import AudioPlaybackChangedEvent from "../Events/AudioPlaybackChangedEvent";
export default class {
    constructor(pubSub, snowplowClient, contextsHandler) {
        pubSub.subscribe("audioUnpaused", (eventName, eventData) => {
            AudioPlaybackChangedEvent({
                "action": "unpause",
                "eventAction": "audioUnpaused",
                "audioId": eventData.audioId,
                "audioUrl": eventData.audioUrl,
                "audioUrlHash": eventData.audioUrlHash,
                "audioTitle": eventData.audioTitle,
                "audioType": eventData.audioType,
                "playbackPosition": eventData.position,
                "triggeredBy": eventData.triggeredBy,
            }, snowplowClient, contextsHandler);
        });
    }
}
