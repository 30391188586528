import VideoEvent from "../Events/VideoEvent";
export default class {
    constructor(pubSub, snowplowClient, contextsHandler) {
        pubSub.subscribe("videoStarted", (eventName, eventData) => {
            VideoEvent({
                "eventAction": "videoStarted",
                "videoId": eventData.videoId,
                "videoTitle": eventData.videoTitle,
                "autoplay": eventData.autoplay,
            }, snowplowClient, contextsHandler);
        });
    }
}
