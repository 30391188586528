import SendTimeSpentEvent from "../Events/TimeSpentEvent";
export default class {
    constructor(pubSub, snowplowClient, contextsHandler) {
        pubSub.subscribe("timeSpent", (eventName, eventData) => {
            SendTimeSpentEvent({
                "eventAction": "timeSpent",
                "timeOnPage": eventData.duration,
            }, snowplowClient, contextsHandler);
        });
    }
}
