import SendWebScrollEvent from "../Events/WebScrollEvent";
export default class {
    constructor(pubSub, snowplowClient, contextsHandler) {
        const eventName = "scrolledToBeginOfBlock";
        pubSub.subscribe(eventName, (eventName, eventData) => {
            SendWebScrollEvent({
                eventAction: eventName,
                name: eventData.name
            }, snowplowClient, contextsHandler);
        });
    }
}
