import VideoEvent from "../Events/VideoEvent";
export default class {
    constructor(pubSub, snowplowClient, contextsHandler) {
        pubSub.subscribe("videoPaused", (eventName, eventData) => {
            VideoEvent({
                "eventAction": "videoPaused",
                "videoId": eventData.videoId,
                "videoTitle": eventData.videoTitle,
                "positionInMS": eventData.position,
            }, snowplowClient, contextsHandler);
        });
    }
}
