import ShareButtonClickedEvent from "../Events/ShareButtonClickedEvent";
export default class {
    constructor(pubSub, snowplowClient, contextsHandler) {
        pubSub.subscribe("shareButtonClicked", (eventName, eventData) => {
            ShareButtonClickedEvent({
                eventAction: "shareButtonClicked",
                network: eventData.network,
                location: eventData.location,
                eventLabel: eventData.network
            }, snowplowClient, contextsHandler);
        });
    }
}
