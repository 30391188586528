import LinkClickedEvent from "../Events/LinkClickedEvent";
export default class {
    constructor(pubSub, snowplowClient, contextsHandler) {
        pubSub.subscribe("linkClicked", (eventName, eventData) => {
            LinkClickedEvent({
                eventAction: "linkClicked",
                clickURL: eventData.url,
                eventLabel: eventData.label,
                zone: eventData.location,
                type: eventData.type,
                objectId: eventData.objectId,
                objectLegacyId: eventData.objectLegacyId,
                position: eventData.position
            }, snowplowClient, contextsHandler);
        });
    }
}
