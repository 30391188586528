var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _ContextsHandler_brazeEnabled, _ContextsHandler_state;
import PodcastContext from "./Context/PodcastContext";
import ReaderConsentContext from "./Context/ReaderConsentContext";
import BrazeContext from "./Context/BrazeContext";
import WebPageContext from "./Context/WebPageContext";
import WebReferrerContext from "./Context/WebReferrerContext";
import WebSearchContext from "./Context/WebSearchContext";
import WebUserContext from "./Context/WebUserContext";
class ContextsHandler {
    constructor(state, brazeEnabled) {
        _ContextsHandler_brazeEnabled.set(this, void 0);
        _ContextsHandler_state.set(this, void 0);
        __classPrivateFieldSet(this, _ContextsHandler_state, state, "f");
        __classPrivateFieldSet(this, _ContextsHandler_brazeEnabled, brazeEnabled, "f");
    }
    getGlobalContexts() {
        return [
            this._getBrazeContext(),
            this._getWebUserContext(),
            this._getWebPageContext(),
            this._getWebReferrerContext(),
            this._getReaderConsentContext(),
        ].filter((context) => context !== null);
    }
    getWebSearchContext() {
        const webSearchContext = new WebSearchContext();
        webSearchContext.data = {
            query: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getSearchQuery(),
            sort: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getSearchSort(),
            pageNumber: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getSearchPageNumber(),
            qtyPerPage: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getSearchQtyPerPage(),
        };
        return webSearchContext;
    }
    getPodcastContext() {
        const podcastContext = new PodcastContext();
        podcastContext.data = {
            id: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getPodcastId(),
            seasonId: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getPodcastSeasonId(),
        };
        return podcastContext;
    }
    _getBrazeContext() {
        if (!__classPrivateFieldGet(this, _ContextsHandler_brazeEnabled, "f")) {
            return null;
        }
        const brazeContext = new BrazeContext();
        brazeContext.data = {
            deviceId: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getBrazeDeviceId(),
        };
        return brazeContext;
    }
    _getWebUserContext() {
        const webUserContext = new WebUserContext();
        webUserContext.data = {
            readerId: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getUserId(),
            deviceInstanceId: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getDeviceId(),
            isLoggedIn: !!__classPrivateFieldGet(this, _ContextsHandler_state, "f").getUserId(),
        };
        return webUserContext;
    }
    _getWebPageContext() {
        const webPageContext = new WebPageContext();
        webPageContext.data = {
            view: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getPageView(),
            type: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getPageType(),
            storyPublicationType: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getPageStoryPublicationType(),
            parentId: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getPageParentId(),
            section: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getPageSection(),
            subSection: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getPageSubSection(),
            objectId: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getPageObjectId(),
            isDarkModeEnabled: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getIsDarkModeEnabled(),
        };
        return webPageContext;
    }
    _getReaderConsentContext() {
        const readerConsentContext = new ReaderConsentContext();
        readerConsentContext.data = {
            advertising: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getReaderConsentAdvertising(),
            productAndPhilanthropy: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getReaderConsentProductAndPhilanthropy(),
        };
        return readerConsentContext;
    }
    _getWebReferrerContext() {
        const webReferrerContext = new WebReferrerContext();
        webReferrerContext.data = {
            eh: __classPrivateFieldGet(this, _ContextsHandler_state, "f").getReferrer(),
        };
        return webReferrerContext;
    }
}
_ContextsHandler_brazeEnabled = new WeakMap(), _ContextsHandler_state = new WeakMap();
export default ContextsHandler;
