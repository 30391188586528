import SendPostViewedEvent from "../Events/PostViewedEvent";
export default class {
    constructor(pubSub, snowplowClient, contextsHandler) {
        pubSub.subscribe("postViewed", (eventName, eventData) => {
            SendPostViewedEvent({
                "storyId": eventData.storyId,
            }, snowplowClient, contextsHandler);
        });
    }
}
