import VideoEvent from "../Events/VideoEvent";
export default class {
    constructor(pubSub, snowplowClient, contextsHandler) {
        pubSub.subscribe("videoReady", (eventName, eventData) => {
            VideoEvent({
                "eventAction": "videoReady",
                "videoId": eventData.videoId,
                "videoTitle": eventData.videoTitle,
                "durationInMS": eventData.durationInMS,
            }, snowplowClient, contextsHandler);
        });
    }
}
